<template>
  <div>
    <div>
      <div class="page-wrapper chiller-theme" style="margin-top: 71px">
        <main class="page-content">
          <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
                <li>
                  <router-link to="/demo/usa/platform/models">
                    <span>Model</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/demo/usa/platform/stats">
                    <span>Sale Statistics</span>
                  </router-link>
                </li>
                <li
                  class="ProximaNovaBold"
                  v-if="activeTab === 'Causal Insights'"
                >
                  Causal Insights
                </li>
                <li
                  class="ProximaNovaBold"
                  v-if="activeTab === 'Future Planning'"
                >
                  Future Planning
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
                  ROI Summary
                </li>
              </ul>
              <div>
                <div class="" style="height: 40%">
                  <div class="w-100 p-4 pr-5">
                    <w-white-dropdown
                      :options="marketDropdown"
                      :placeHolder="'Select'"
                      :selectedOption="selectedMarket"
                      :labelText="'Market type'"
                      class="mr-3"
                      @input="selectMarket($event)"
                    ></w-white-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-5">
            <w-tab
              :tabName="activeTab"
              :tabs="tabs"
              @activeTab="changeTab($event)"
            >
            </w-tab>
            <div v-if="activeTab === 'Causal Insights'">
              <div class="mt-5" :class="breakDown ? 'card' : ''">
                <b-row class="no-gutters">
                  <b-col lg="4" :class="breakDown ? '' : 'card'">
                    <div>
                      <pie-chart
                        title="Overall"
                        :colors="pieChartColor"
                        :series="overallSeries"
                        :showBreakdown="true"
                        :breakdown="breakDown"
                        @getBreakdown="getBreakdown"
                      />
                    </div>
                  </b-col>
                  <b-col lg="8" v-if="breakDown" class="breakdownable-card">
                    <multiple-donut-chart
                      :series1="externalData"
                      :series2="internalData"
                      :chart2Colors="chart2Color"
                      :title="multipleTitle"
                    />
                  </b-col>
                </b-row>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :stripData="mediaData"
                    :title="'Media'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="tvExpandData"
                    :stripData="tvData"
                    :title="'Television'"
                    @getDataBreakdown="gettvBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Digital
                    :data="digitalExpandData"
                    :stripData="digitalData"
                    @getDataBreakdown="getDigitalBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="printExpandData"
                    :stripData="printData"
                    :title="'Print'"
                    @getDataBreakdown="getPrintBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="radioExpandData"
                    :stripData="radioData"
                    :title="'Radio'"
                    @getDataBreakdown="getRadioBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="sponsorshipExpandData"
                    :stripData="sponsorshipData"
                    :title="'Sponsorship'"
                    @getDataBreakdown="getSponsorshipBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="contentExpandData"
                    :stripData="contentData"
                    @getDataBreakdown="getContentBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="billboardExpandData"
                    :title="'Billboard'"
                    :stripData="billboardData"
                    @getDataBreakdown="getBillboardBreakdown"
                  />
                </b-container>
              </div>
            </div>
            <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
            <w-future-planning
              v-if="activeTab === 'Future Planning'"
            ></w-future-planning>
          </div>
          <page-loader v-if="count < 9"></page-loader>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "./ROI-Summary.vue";
import FuturePlanning from "@/pages/DEMO/AIMO-USA/FuturePlanning.vue";
import { AimoUSAServices } from "@/services/AimoUSAServices.js";
import PageLoader from "@/widgets/PageLoader.vue";
const aimoUSAService = new AimoUSAServices();
export default {
  name: "Insights",
  components: {
    PageLoader,
    "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    PieChart,
    Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      mediaData: [],
      pieChartColor: ["#ff4081", "#64ffda"],
      chart2Color: [
        "#6a1b9a",
        "#d81b60",
        "#1e88e5",
        "#a71313",
        "#5e35b1",
        "#00acc1",
        "#fb8c00",
        "#d81b60",
        "#64ffda",
      ],
      count: 0,
      breakDown: true,
      billboardData: [],
      billboardExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      contentBreakDown: false,
      activeTab: "Causal Insights",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Causal Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI Summary",
        },
      ],
      marketDropdown: [
        { text: "USA", id: "usa" },
        { text: "All India", id: "allindia", disable: true },
        { text: "HSM", id: "hsm", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
            },
            {
              name: "External",
              y: 77,
              sliced: true,
            },
          ],
        },
      ],
      color: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      tvData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      printData: [],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Consumer Sentiment",
          y: 12,
          z: 92.9,
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Media",
          y: 52,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Product",
          y: 22,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Promotions",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Distribution",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
        {
          name: "PR",
          y: 4,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id5",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      tvExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 50,
              x2: 60,
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 1,
              x2: 20,
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: 20,
              x2: 30,
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: 60,
              x2: 100,
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
      ],
      printExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      sponsorshipData: [],
      sponsorshipExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioData: [],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("kpiMarket"));
    this.getOverallChartData();
    this.getExternalData();
    this.getInternalData();
    this.getMediaData();
    this.getFirstLevelData("tvData", "tv");
    this.getFirstLevelData("digitalData", "digital");
    this.getFirstLevelData("printData", "print");
    this.getFirstLevelData("radioData", "radio");
    this.getFirstLevelData("sponsorshipData", "sponsorship");
    this.getFirstLevelData("contentData", "content");
    this.getFirstLevelData("billboardData", "billboardlevelone");
  },
  methods: {
    getFirstLevelData(dataVariable, param) {
      var color = "";
      if (param === "content") {
        color = "rgb(251,140,0)";
      } else if (param === "tv") {
        color = "rgb(216,27,96)";
      } else if (param === "print") {
        color = "rgb(94,53,177)";
      } else if (param === "radio") {
        color = "rgb(167,19,19)";
      } else if (param === "sponsorship") {
        color = "rgb(30,136,229)";
      } else if (param === "billboardlevelone") {
        color = "rgb(0,172,193)";
      } else if (param === "digital") {
        color = "rgb(106,27,154)";
      } else {
        color = "rgb(247,127,0)";
      }
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", param, "usa")
        .then((res) => {
          var opacity = 1;
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if (i <= 7) {
              eachOpacity = opacity / (i + 1);
            } else {
              eachOpacity = opacity / 8;
            }
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getDigitalBreakdown(name) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          name.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: true,
              },
            });
            count += res[i].value;
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getPrintBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          data.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.printExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.printExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getRadioBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          data.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.radioExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.radioExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getSponsorshipBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          data.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.sponsorshipExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.sponsorshipExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getContentBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          data.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getBillboardBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/usa/",
          data.label.toLowerCase(),
          "usa"
        )
        .then((res) => {
          this.billboardExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.billboardExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getTvExpandData(name) {
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", name.toLowerCase(), "usa")
        .then((res) => {
          this.tvExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.tvExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    gettvBreakdown(data) {
      this.getTvExpandData(data.label);
    },
    getExternalData() {
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", "external factors", "usa")
        .then((res) => {
          this.externalData = [];
          for (var i = 0; i < res.length; i++) {
            var name = ""
            if(res[i].name === 'GDP'){
              name = "Consumer Sentiment"
            }else{
              name = res[i].name
            }
            this.externalData.push({
              name: name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function() {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
        });
    },
    getInternalData() {
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", "internal factors", "usa")
        .then((res) => {
          this.internalData = [];
          for (var i = 0; i < res.length; i++) {
            this.internalData.push({
              name: res[i].name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function() {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getMediaData() {
      var colors = [
        "rgb(216,27,96)",
        "rgb(106,27,154)",
        "rgb(94,53,177)",
        "rgb(167,19,19)",
        "rgb(30,136,229)",
        "rgb(0,172,193)",
        "rgb(247,127,0)",
        "rgb(247,127,0)",
        "rgb(247,127,0)",
      ];
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", "media", "usa")
        .then((res) => {
          this.mediaData = [];
          for (var i = 0; i < res.length; i++) {
            this.mediaData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: colors[i],
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getOverallChartData() {
      aimoUSAService
        .getChartData("sales causality", "/b2c/usa/", "overall", "usa")
        .then((res) => {
          this.overallSeries[0].data = [];
          for (var i = 0; i < res.length; i++) {
            this.overallSeries[0].data.push({
              name: res[i].name,
              y: res[i].value,
              sliced: true,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("kpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      this.selectedMarket = e;
    },
  },
  computed: {
    getColor() {
      return (color, opacity) => {
        var x = color.split(")");
        return x[0] + "," + opacity + ")";
      };
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
